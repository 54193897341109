$ti-font-path: "../../../fonts/themify" !default;

$borderColor: #eeeeee !default;
$iconMuted: #eeeeee !default;
$iconLight: white !default;
$iconDark: #333333 !default;
$icons-li-width: (30em/14);

$wand: "\e600";
$volume: "\e601";
$user: "\e602";
$unlock: "\e603";
$unlink: "\e604";
$trash: "\e605";
$thought: "\e606";
$target: "\e607";
$tag: "\e608";
$tablet: "\e609";
$star: "\e60a";
$spray: "\e60b";
$signal: "\e60c";
$shopping-cart: "\e60d";
$shopping-cart-full: "\e60e";
$settings: "\e60f";
$search: "\e610";
$zoom-in: "\e611";
$zoom-out: "\e612";
$cut: "\e613";
$ruler: "\e614";
$ruler-pencil: "\e615";
$ruler-alt: "\e616";
$bookmark: "\e617";
$bookmark-alt: "\e618";
$reload: "\e619";
$plus: "\e61a";
$pin: "\e61b";
$pencil: "\e61c";
$pencil-alt: "\e61d";
$paint-roller: "\e61e";
$paint-bucket: "\e61f";
$na: "\e620";
$mobile: "\e621";
$minus: "\e622";
$medall: "\e623";
$medall-alt: "\e624";
$marker: "\e625";
$marker-alt: "\e626";
$arrow-up: "\e627";
$arrow-right: "\e628";
$arrow-left: "\e629";
$arrow-down: "\e62a";
$lock: "\e62b";
$location-arrow: "\e62c";
$link: "\e62d";
$layout: "\e62e";
$layers: "\e62f";
$layers-alt: "\e630";
$key: "\e631";
$import: "\e632";
$image: "\e633";
$heart: "\e634";
$heart-broken: "\e635";
$hand-stop: "\e636";
$hand-open: "\e637";
$hand-drag: "\e638";
$folder: "\e639";
$flag: "\e63a";
$flag-alt: "\e63b";
$flag-alt-2: "\e63c";
$eye: "\e63d";
$export: "\e63e";
$exchange-vertical: "\e63f";
$desktop: "\e640";
$cup: "\e641";
$crown: "\e642";
$comments: "\e643";
$comment: "\e644";
$comment-alt: "\e645";
$close: "\e646";
$clip: "\e647";
$angle-up: "\e648";
$angle-right: "\e649";
$angle-left: "\e64a";
$angle-down: "\e64b";
$check: "\e64c";
$check-box: "\e64d";
$camera: "\e64e";
$announcement: "\e64f";
$brush: "\e650";
$briefcase: "\e651";
$bolt: "\e652";
$bolt-alt: "\e653";
$blackboard: "\e654";
$bag: "\e655";
$move: "\e656";
$arrows-vertical: "\e657";
$arrows-horizontal: "\e658";
$fullscreen: "\e659";
$arrow-top-right: "\e65a";
$arrow-top-left: "\e65b";
$arrow-circle-up: "\e65c";
$arrow-circle-right: "\e65d";
$arrow-circle-left: "\e65e";
$arrow-circle-down: "\e65f";
$angle-double-up: "\e660";
$angle-double-right: "\e661";
$angle-double-left: "\e662";
$angle-double-down: "\e663";
$zip: "\e664";
$world: "\e665";
$wheelchair: "\e666";
$view-list: "\e667";
$view-list-alt: "\e668";
$view-grid: "\e669";
$uppercase: "\e66a";
$upload: "\e66b";
$underline: "\e66c";
$truck: "\e66d";
$timer: "\e66e";
$ticket: "\e66f";
$thumb-up: "\e670";
$thumb-down: "\e671";
$text: "\e672";
$stats-up: "\e673";
$stats-down: "\e674";
$split-v: "\e675";
$split-h: "\e676";
$smallcap: "\e677";
$shine: "\e678";
$shift-right: "\e679";
$shift-left: "\e67a";
$shield: "\e67b";
$notepad: "\e67c";
$server: "\e67d";
$quote-right: "\e67e";
$quote-left: "\e67f";
$pulse: "\e680";
$printer: "\e681";
$power-off: "\e682";
$plug: "\e683";
$pie-chart: "\e684";
$paragraph: "\e685";
$panel: "\e686";
$package: "\e687";
$music: "\e688";
$music-alt: "\e689";
$mouse: "\e68a";
$mouse-alt: "\e68b";
$money: "\e68c";
$microphone: "\e68d";
$menu: "\e68e";
$menu-alt: "\e68f";
$map: "\e690";
$map-alt: "\e691";
$loop: "\e692";
$location-pin: "\e693";
$list: "\e694";
$light-bulb: "\e695";
$Italic: "\e696";
$info: "\e697";
$infinite: "\e698";
$id-badge: "\e699";
$hummer: "\e69a";
$home: "\e69b";
$help: "\e69c";
$headphone: "\e69d";
$harddrives: "\e69e";
$harddrive: "\e69f";
$gift: "\e6a0";
$game: "\e6a1";
$filter: "\e6a2";
$files: "\e6a3";
$file: "\e6a4";
$eraser: "\e6a5";
$envelope: "\e6a6";
$download: "\e6a7";
$direction: "\e6a8";
$direction-alt: "\e6a9";
$dashboard: "\e6aa";
$control-stop: "\e6ab";
$control-shuffle: "\e6ac";
$control-play: "\e6ad";
$control-pause: "\e6ae";
$control-forward: "\e6af";
$control-backward: "\e6b0";
$cloud: "\e6b1";
$cloud-up: "\e6b2";
$cloud-down: "\e6b3";
$clipboard: "\e6b4";
$car: "\e6b5";
$calendar: "\e6b6";
$book: "\e6b7";
$bell: "\e6b8";
$basketball: "\e6b9";
$bar-chart: "\e6ba";
$bar-chart-alt: "\e6bb";
$back-right: "\e6bc";
$back-left: "\e6bd";
$arrows-corner: "\e6be";
$archive: "\e6bf";
$anchor: "\e6c0";
$align-right: "\e6c1";
$align-left: "\e6c2";
$align-justify: "\e6c3";
$align-center: "\e6c4";
$alert: "\e6c5";
$alarm-clock: "\e6c6";
$agenda: "\e6c7";
$write: "\e6c8";
$window: "\e6c9";
$widgetized: "\e6ca";
$widget: "\e6cb";
$widget-alt: "\e6cc";
$wallet: "\e6cd";
$video-clapper: "\e6ce";
$video-camera: "\e6cf";
$vector: "\e6d0";
$themify-logo: "\e6d1";
$themify-favicon: "\e6d2";
$themify-favicon-alt: "\e6d3";
$support: "\e6d4";
$stamp: "\e6d5";
$split-v-alt: "\e6d6";
$slice: "\e6d7";
$shortcode: "\e6d8";
$shift-right-alt: "\e6d9";
$shift-left-alt: "\e6da";
$ruler-alt-2: "\e6db";
$receipt: "\e6dc";
$pin2: "\e6dd";
$pin-alt: "\e6de";
$pencil-alt2: "\e6df";
$palette: "\e6e0";
$more: "\e6e1";
$more-alt: "\e6e2";
$microphone-alt: "\e6e3";
$magnet: "\e6e4";
$line-double: "\e6e5";
$line-dotted: "\e6e6";
$line-dashed: "\e6e7";
$layout-width-full: "\e6e8";
$layout-width-default: "\e6e9";
$layout-width-default-alt: "\e6ea";
$layout-tab: "\e6eb";
$layout-tab-window: "\e6ec";
$layout-tab-v: "\e6ed";
$layout-tab-min: "\e6ee";
$layout-slider: "\e6ef";
$layout-slider-alt: "\e6f0";
$layout-sidebar-right: "\e6f1";
$layout-sidebar-none: "\e6f2";
$layout-sidebar-left: "\e6f3";
$layout-placeholder: "\e6f4";
$layout-menu: "\e6f5";
$layout-menu-v: "\e6f6";
$layout-menu-separated: "\e6f7";
$layout-menu-full: "\e6f8";
$layout-media-right-alt: "\e6f9";
$layout-media-right: "\e6fa";
$layout-media-overlay: "\e6fb";
$layout-media-overlay-alt: "\e6fc";
$layout-media-overlay-alt-2: "\e6fd";
$layout-media-left-alt: "\e6fe";
$layout-media-left: "\e6ff";
$layout-media-center-alt: "\e700";
$layout-media-center: "\e701";
$layout-list-thumb: "\e702";
$layout-list-thumb-alt: "\e703";
$layout-list-post: "\e704";
$layout-list-large-image: "\e705";
$layout-line-solid: "\e706";
$layout-grid4: "\e707";
$layout-grid3: "\e708";
$layout-grid2: "\e709";
$layout-grid2-thumb: "\e70a";
$layout-cta-right: "\e70b";
$layout-cta-left: "\e70c";
$layout-cta-center: "\e70d";
$layout-cta-btn-right: "\e70e";
$layout-cta-btn-left: "\e70f";
$layout-column4: "\e710";
$layout-column3: "\e711";
$layout-column2: "\e712";
$layout-accordion-separated: "\e713";
$layout-accordion-merged: "\e714";
$layout-accordion-list: "\e715";
$ink-pen: "\e716";
$info-alt: "\e717";
$help-alt: "\e718";
$headphone-alt: "\e719";
$hand-point-up: "\e71a";
$hand-point-right: "\e71b";
$hand-point-left: "\e71c";
$hand-point-down: "\e71d";
$gallery: "\e71e";
$face-smile: "\e71f";
$face-sad: "\e720";
$credit-card: "\e721";
$control-skip-forward: "\e722";
$control-skip-backward: "\e723";
$control-record: "\e724";
$control-eject: "\e725";
$comments-smiley: "\e726";
$brush-alt: "\e727";
$youtube: "\e728";
$vimeo: "\e729";
$twitter: "\e72a";
$time: "\e72b";
$tumblr: "\e72c";
$skype: "\e72d";
$share: "\e72e";
$share-alt: "\e72f";
$rocket: "\e730";
$pinterest: "\e731";
$new-window: "\e732";
$microsoft: "\e733";
$list-ol: "\e734";
$linkedin: "\e735";
$layout-sidebar-2: "\e736";
$layout-grid4-alt: "\e737";
$layout-grid3-alt: "\e738";
$layout-grid2-alt: "\e739";
$layout-column4-alt: "\e73a";
$layout-column3-alt: "\e73b";
$layout-column2-alt: "\e73c";
$instagram: "\e73d";
$google: "\e73e";
$github: "\e73f";
$flickr: "\e740";
$facebook: "\e741";
$dropbox: "\e742";
$dribbble: "\e743";
$apple: "\e744";
$android: "\e745";
$save: "\e746";
$save-alt: "\e747";
$yahoo: "\e748";
$wordpress: "\e749";
$vimeo-alt: "\e74a";
$twitter-alt: "\e74b";
$tumblr-alt: "\e74c";
$trello: "\e74d";
$stack-overflow: "\e74e";
$soundcloud: "\e74f";
$sharethis: "\e750";
$sharethis-alt: "\e751";
$reddit: "\e752";
$pinterest-alt: "\e753";
$microsoft-alt: "\e754";
$linux: "\e755";
$jsfiddle: "\e756";
$joomla: "\e757";
$html5: "\e758";
$flickr-alt: "\e759";
$email: "\e75a";
$drupal: "\e75b";
$dropbox-alt: "\e75c";
$css3: "\e75d";
$rss: "\e75e";
$rss-alt: "\e75f";
