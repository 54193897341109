/**=====================
     Dashboard 4 CSS Start
==========================**/
.widget-hover {
  &:hover {
    transform: translateY(-5px);
    transition: 0.5s;
  }
}
.currency-widget {
  .currency-icon-widget {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: inset 2.66667px -2.66667px 2.66667px rgba(194, 128, 49, 0.1), inset -2.66667px 2.66667px 2.66667px rgba(255, 255, 255, 0.1);
    svg {
      width: 16px;
      height: 16px;
      fill: $white;
    }
  }
  .card {
    margin-top: 14px;
    .card-body {
      padding: 15px;
      gap: 10px;
      justify-content: space-between;
      @media (max-width: 1660px) {
        flex-wrap: wrap;
      }
      @media (max-width: 575px) {
        flex-wrap: unset;
      }
    }
    &:hover {
      transform: translateY(-5px);
      transition: 0.5s;
    }
  }
  h6 {
    margin-bottom: 0;
  }
  .d-flex {
    gap: 10px;
    align-items: center;
  }
  span {
    svg {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
  div[class*="bg-light-"] {
    color: $theme-body-font-color;
    padding: 15px;
    border-radius: 10px;
    width: 115px;
    @media (max-width: 1660px) and (min-width: 1200px) {
      display: none;
    }
    @media (max-width: 1199px) {
      width: 100%;
    }
    @media (max-width: 575px) {
      width: 50%;
    }
  }
  &.widget-currency {
    .card {
      .card-body {
        @media (max-width: 1199px) and (min-width: 768px) {
          flex-wrap: unset;
        }
      }
    }
  }
  &.warning {
    .currency-icon-widget {
      background-image: linear-gradient(to right, $warning-color 80%, rgba($warning-color, 0.2));
    }
  }
  &.primary {
    .currency-icon-widget {
      background-image: linear-gradient(to right, $primary-color 80%, rgba($primary-color, 0.2));
    }
  }
  &.success {
    .currency-icon-widget {
      background-image: linear-gradient(to right, $success-color 80%, rgba($success-color, 0.2));
    }
  }
  &.height-equal {
    @media (max-width: 1399px) {
      min-height: unset !important;
    }
  }
}
.currency-chart-wrap {
  margin: -28px -10px -20px -20px;
  @media (max-width: 575px) {
    width: 50%;
  }
  .apexcharts-canvas {
    .apexcharts-tooltip-text-y-label {
      display: none;
    }
  }
}
.tranaction-card {
  .header-top {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.crypto-main-card {
  background-image: linear-gradient(to bottom right, #5f3deb, #ad79ff);
  color: $white;
  .card-body {
    @media (max-width: 1199px) {
      padding: 20px;
    }
  }
  .btn {
    padding: 8px 20px;
    @media (max-width: 1660px) {
      padding: 8px 15px;
    }
  }
  .deposit-wrap {
    > div {
      width: 65%;
      @media (max-width: 1660px) {
        width: 90%;
      }
      @media (max-width: 1536px) {
        width: 100%;
      }
      @media (max-width: 1399px) {
        width: 75%;
      }
      @media (max-width: 1199px) {
        width: 95%;
      }
      h5 {
        @media (max-width: 1660px) {
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
        }
        @media (max-width: 767px) {
          -webkit-line-clamp: 1;
        }
      }
    }
    img {
      position: absolute;
      right: 10px;
      bottom: 10px;
      width: 55%;
      [dir="rtl"] & {
        right: unset;
        left: 10px;
        transform: scaleX(-1);
      }
      @media (max-width: 1660px) {
        width: 44%;
      }
      @media (max-width: 1399px) {
        width: 52%;
      }
      @media (max-width: 1199px) {
        width: 30%;
      }
    }
  }
}
.portfolio-card {
  .dropdown-toggle {
    width: 80px;
  }
}
.btn-white {
  color: $primary-color;
  background-color: $white;
}
.radial-progress-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  &.card-body {
    padding: 23px 20px;
    @media (max-width: 1199px) {
      padding: 30px 20px;
    }
  }
  p {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  h6 {
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
  }

  .sale-details {
    margin: 10px 0;
    svg {
      width: 15px;
      height: 15px;
      vertical-align: middle;
    }
  }
  .radial-chart-wrap {
    width: 110px;
    margin-right: -25px;
    [dir="rtl"] & {
      margin-right: unset;
      margin-left: -25px;
    }
    @media (max-width: 1820px) {
      margin-right: -12px;
      [dir="rtl"] & {
        margin-left: -12px;
      }
    }
    .apexcharts-canvas {
      .apexcharts-datalabel-label {
        display: none;
      }
    }
  }
}
.transaction-table {
  table {
    tr {
      &:first-child {
        td {
          padding-top: 0;
        }
      }
      td {
        &:first-child {
          min-width: unset;
          @media (max-width: 1775px) {
            min-width: 130px;
          }
        }
        &:last-child {
          text-align: right;
          min-width: 60px;
        }
      }
    }
    tr,
    td {
      border-style: dashed;
    }
  }
}
.custom-tab {
  background: var(--light2);
  padding: 4px;
  border-radius: 5px;
  &.nav-tabs {
    border: none;
    .nav-link {
      border: none;
      padding: 6px 12px;
      color: var(--chart-text-color);
      &.active {
        color: var(--theme-deafult);
      }
    }
  }
}
.market-chart-container {
  margin: -24px 0 -24px -15px;
  [dir="rtl"] & {
    margin: -24px -15px -24px 0;
  }
  @media (max-width: 575px) {
    margin-bottom: 0;
  }
}
.market-card {
  .balance-card {
    padding: 10px;
  }
  .custom-tab {
    @media (max-width: 575px) {
      display: none;
    }
  }
}
.currency-icon {
  width: 34px;
  height: 34px;
  background-color: var(--light2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.warning {
    svg {
      fill: $warning-color;
    }
  }
  &.success {
    svg {
      fill: $success-color;
    }
  }
  &.primary {
    svg {
      fill: $primary-color;
    }
  }
  &.secondary {
    svg {
      fill: $secondary-color;
    }
  }
  &.dark-green {
    svg {
      fill: #1ba27a;
    }
  }
  &.light-blue {
    svg {
      fill: #038de7;
    }
  }
}
.recent-table {
  tr {
    &:hover {
      td {
        &:first-child {
          h6 {
            color: var(--theme-deafult);
          }
        }
      }
      .border-icon {
        .social-circle {
          transform: scale(1.02);
          transition: 0.5s;
        }
      }
      .currency-icon {
        svg {
          animation: tada 1.5s ease infinite;
        }
      }
      .badge-light-primary {
        background-color: var(--theme-deafult);
        color: $white;
      }
    }
  }
}
.currency-table {
  .change-currency {
    svg {
      width: 16px;
      height: 16px;
      vertical-align: sub;
    }
  }
  th,
  td {
    &:nth-child(3) {
      min-width: 110px;
    }
    &:nth-child(4) {
      min-width: 115px;
    }
  }
}

select {
  &.crypto-select {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    right: 7px;
    width: 62px;
    background-position: right 0.25rem center;
    padding: 8px;
    border: none;
    font-weight: 500;
    background-size: 8px;
    [dir="rtl"] & {
      right: unset;
      left: 7px;
    }
    &.warning {
      background-color: rgba($warning-color, 0.1) !important;
      color: $warning-color !important;
    }
    &.primary {
      background-color: rgba($primary-color, 0.1) !important;
      color: $primary-color !important;
    }
  }
}

.crypto-form {
  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
.balance-box {
  text-align: center;
  background-image: url(../../images/dashboard-4/bg-balance.png);
  background-position: right;
  background-size: cover;
  .balance-img {
    display: inline-block;
    padding: 5px;
    border: 2px solid var(--chart-border);
    border-left-color: $success-color;
    border-bottom-color: $success-color;
    border-radius: 100%;
    position: relative;
    margin-bottom: 15px;
    img {
      background-color: var(--balance-profie-bg);
      border-radius: 100%;
    }
    .edit-icon {
      width: 26px;
      height: 26px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--white);
      background-color: #dfdff4;
      right: 0;
      bottom: 0;
      border-radius: 100%;
      svg {
        width: 11px;
        height: 11px;
        fill: $primary-color;
      }
    }
  }
}
.balance-profile {
  ul {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 16px;
    @media (max-width: 1660px) {
      gap: 25px;
    }
    li {
      position: relative;
      + li {
        &::before {
          position: absolute;
          content: "";
          left: -25px;
          top: 50%;
          height: 40px;
          transform: translateY(-50%);
          border: 1px dashed var(--recent-dashed-border);
          [dir="rtl"] & {
            left: unset;
            right: -25px;
          }
          @media (max-width: 1660px) {
            left: -16px;
            [dir="rtl"] & {
              right: -16px;
            }
          }
        }
      }
    }
  }
}
.activity-card {
  .appointment-table {
    @media (max-width: 1399px) {
      height: 180px;
    }
    @media (max-width: 575px) {
      height: unset;
    }
    tr {
      td {
        &:nth-child(2) {
          min-width: 130px;
        }
      }
    }
  }
}
.balance-item {
  display: flex;
  gap: 10px;
  .balance-icon-wrap {
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    @media (max-width: 1660px) {
      width: 25px;
      min-width: 25px;
      height: 25px;
      border-radius: 8px;
    }
    .balance-icon {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      @media (max-width: 1660px) {
        width: 14px;
        height: 14px;
      }
      svg {
        color: $white;
        width: 14px;
        height: 14px;
        @media (max-width: 1660px) {
          width: 12px;
          height: 12px;
          vertical-align: text-top;
        }
      }
    }
  }
  .badge {
    padding: 8px 10px;
  }
  &.danger {
    .balance-icon-wrap {
      background-color: rgba($danger-color, 0.1);
      .balance-icon {
        background-color: $danger-color;
      }
    }
  }
  &.success {
    .balance-icon-wrap {
      background-color: rgba($success-color, 0.1);
      .balance-icon {
        background-color: $success-color;
      }
    }
  }
}
.portfolio-chart-container {
  margin-top: -56px;
  @media (max-width: 1399px) {
    margin-top: -20px;
  }
}
.portfolio-table {
  .status {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    display: inline-block;
  }
  table {
    tr,
    td {
      border-style: dashed;
    }
    tr {
      td,
      th {
        &:first-child {
          @media (max-width: 1755px) {
            min-width: 100px;
          }
        }
        &:last-child {
          @media (max-width: 1755px) {
            min-width: 128px;
          }
        }
      }
    }
  }
}
